import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue";
import _imports_0 from '@/assets/images/dine-in.svg';
import _imports_1 from '@/assets/images/takeaway.svg';
const _hoisted_1 = {
  class: "order-confirmation"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  class: "restaurant-info"
};
const _hoisted_5 = {
  class: "store-name"
};
const _hoisted_6 = {
  class: "address"
};
const _hoisted_7 = {
  class: "dining-options"
};
const _hoisted_8 = {
  class: "order-details"
};
const _hoisted_9 = {
  class: "product-info"
};
const _hoisted_10 = {
  key: 0,
  class: "product-name"
};
const _hoisted_11 = {
  class: "product-description-container"
};
const _hoisted_12 = {
  class: "product-descriptions"
};
const _hoisted_13 = {
  class: "footer"
};
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useOrderStore } from '@/stores/orderStore';
import { createOrder } from '@/api/store';
import TopNav from '@/components/TopNav';
import LoadingOverlay from '@/components/LoadingOverlay';
import OrderDining from './order-dining.vue';
import PickupDialog from './pickup-dialog.vue';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const orderStore = useOrderStore();
    const restaurant = ref(orderStore.restaurant);
    const products = ref(orderStore.products);
    const diningOption = ref('dine-in');
    const loading = ref(false);
    const showPickup = ref(false);
    const setDiningOption = option => {
      diningOption.value = option;
    };
    const goBack = () => {
      router.back();
    };
    const goToOrderDetails = () => {
      router.replace('/orders');
    };
    const pickupType = computed(() => {
      return diningOption.value === 'takeaway' && restaurant.value.pickupDiningCabinet === 1 ? 2 : 1;
    });
    const handlePickupConfirm = async () => {
      await processOrder();
    };
    const confirmOrder = async () => {
      if (pickupType.value === 2) {
        console.log('showPickupReminder');
        showPickup.value = true;
      } else {
        await processOrder();
      }
    };
    const processOrder = async () => {
      const orderItems = products.value.map(product => {
        const items = product.productSelectDetail.rounds.flatMap(round => round.items.map(item => ({
          ...item
        })));
        return {
          name: product.productName,
          productId: product.productId,
          couponId: product.couponId,
          id: product.id,
          packageId: product.packageId,
          seq: product.seq,
          groupId: product.groupId,
          packageName: product.packageName,
          packageCode: product.packageCode,
          items: items
        };
      });
      const payload = {
        store_code: restaurant.value.storeCode,
        store_name: restaurant.value.storeName,
        dining_method: diningOption.value === 'dine-in' ? 1 : 2,
        pickup_type: pickupType.value,
        card_code: orderStore.code,
        order_items: JSON.stringify(orderItems)
      };

      // console.log(JSON.parse(payload.order_items));
      // return
      loading.value = true;
      try {
        const response = await createOrder(payload);
        if (response.code === 200) {
          ElMessage({
            message: '订单已确认',
            type: 'success'
          });
          goToOrderDetails();
        }
      } catch (error) {
        console.log(error);
      } finally {
        loading.value = false;
      }
    };
    return (_ctx, _cache) => {
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_card = _resolveComponent("el-card");
      const _component_el_image = _resolveComponent("el-image");
      const _component_el_button = _resolveComponent("el-button");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(TopNav), {
        title: "确定订单"
      })]), _createVNode(_unref(LoadingOverlay), {
        visible: loading.value
      }, null, 8, ["visible"]), _createElementVNode("div", _hoisted_3, [_createVNode(_component_el_card, {
        class: "restaurant-card"
      }, {
        default: _withCtx(() => [_cache[5] || (_cache[5] = _createElementVNode("div", {
          slot: "header",
          class: "clearfix"
        }, [_createElementVNode("span", null, "到店取餐")], -1)), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_4, [_createElementVNode("p", _hoisted_5, "📍 " + _toDisplayString(restaurant.value.storeName), 1), _createElementVNode("p", _hoisted_6, _toDisplayString(restaurant.value.address), 1), _createElementVNode("div", _hoisted_7, [_createElementVNode("div", {
          class: _normalizeClass([{
            selected: diningOption.value === 'dine-in'
          }, "dining-option-button"]),
          onClick: _cache[0] || (_cache[0] = $event => setDiningOption('dine-in'))
        }, _cache[3] || (_cache[3] = [_createElementVNode("img", {
          src: _imports_0,
          alt: "堂食",
          class: "option-icon"
        }, null, -1), _createElementVNode("div", {
          class: "option-text"
        }, [_createElementVNode("span", {
          class: "option-text-title"
        }, "堂食"), _createElementVNode("br"), _createElementVNode("span", {
          class: "option-subtext"
        }, "店内用餐")], -1)]), 2), _createElementVNode("div", {
          class: _normalizeClass([{
            selected: diningOption.value === 'takeaway'
          }, "dining-option-button"]),
          onClick: _cache[1] || (_cache[1] = $event => setDiningOption('takeaway'))
        }, _cache[4] || (_cache[4] = [_createElementVNode("img", {
          src: _imports_1,
          alt: "外带",
          class: "option-icon"
        }, null, -1), _createElementVNode("div", {
          class: "option-text"
        }, [_createElementVNode("span", {
          class: "option-text-title"
        }, "外带"), _createElementVNode("br"), _createElementVNode("span", {
          class: "option-subtext"
        }, "店内自取")], -1)]), 2)])])]),
        _: 1
      }), _createVNode(OrderDining, {
        restaurant: restaurant.value,
        diningOption: diningOption.value
      }, null, 8, ["restaurant", "diningOption"]), _createVNode(_component_el_card, {
        class: "order-card"
      }, {
        default: _withCtx(() => [_cache[6] || (_cache[6] = _createElementVNode("div", {
          slot: "header",
          class: "clearfix"
        }, [_createElementVNode("span", null, "餐品详情")], -1)), _createVNode(_component_el_divider), _createElementVNode("div", _hoisted_8, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(products.value, product => {
          return _openBlock(), _createElementBlock("div", {
            key: product.productId,
            class: "order-item"
          }, [_createVNode(_component_el_image, {
            class: "product-image",
            src: product.imageUrl,
            fit: "cover"
          }, null, 8, ["src"]), _createElementVNode("div", _hoisted_9, [false ? (_openBlock(), _createElementBlock("h3", _hoisted_10, _toDisplayString(product.productName), 1)) : _createCommentVNode("", true), _createElementVNode("div", _hoisted_11, [_createElementVNode("div", _hoisted_12, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.productSelectDetail?.rounds, round => {
            return _openBlock(), _createElementBlock("div", {
              key: round.id
            }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(round.items, item => {
              return _openBlock(), _createElementBlock("p", {
                class: "product-description",
                key: item.id
              }, [_createTextVNode(_toDisplayString(round.quantity) + " × " + _toDisplayString(item.itemSkuName) + " ", 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(item.productAttributes, attr => {
                return _openBlock(), _createElementBlock("span", {
                  key: attr.id,
                  class: "attr-info"
                }, _toDisplayString(attr.items.find(opt => opt.isPush === 0)?.itemName || '未选择'), 1);
              }), 128))]);
            }), 128))]);
          }), 128))])])])]);
        }), 128))])]),
        _: 1
      })]), _createElementVNode("div", _hoisted_13, [_createVNode(_component_el_button, {
        onClick: goBack,
        round: true
      }, {
        default: _withCtx(() => _cache[7] || (_cache[7] = [_createTextVNode("返回")])),
        _: 1
      }), _createVNode(_component_el_button, {
        type: "success",
        color: "#fe813b",
        onClick: confirmOrder,
        round: true,
        style: {
          "color": "#fff"
        }
      }, {
        default: _withCtx(() => _cache[8] || (_cache[8] = [_createTextVNode("确认下单")])),
        _: 1
      })]), showPickup.value ? (_openBlock(), _createBlock(PickupDialog, {
        key: 0,
        restaurant: restaurant.value,
        onClose: _cache[2] || (_cache[2] = $event => showPickup.value = false),
        onConfirm: handlePickupConfirm
      }, null, 8, ["restaurant"])) : _createCommentVNode("", true)]);
    };
  }
};