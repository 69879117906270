import "core-js/modules/es.array.push.js";
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue";
const _hoisted_1 = {
  class: "meal-selection"
};
const _hoisted_2 = {
  class: "header"
};
const _hoisted_3 = {
  class: "content"
};
const _hoisted_4 = {
  key: 0
};
const _hoisted_5 = {
  key: 1
};
const _hoisted_6 = {
  class: "footer"
};
import { ref, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import TopNav from '@/components/TopNav';
import { useRouter } from 'vue-router';
import StepIndicator from '@/components/StepIndicator';
import { useProductStore } from '@/stores/productStore';
import { useOrderStore } from '@/stores/orderStore';
import ProductItem from '@/components/ProductItem';
import Customization from '@/views/MealSelection/customization';

import { ElMessage } from 'element-plus/es';
import 'element-plus/es/components/base/style/css';
import 'element-plus/es/components/message/style/css';
export default {
  __name: 'index',
  setup(__props) {
    const router = useRouter();
    const productStore = useProductStore();
    const orderStore = useOrderStore();
    const {
      code,
      selectedItems
    } = storeToRefs(orderStore);
    const customizationVisible = ref(false);
    const loading = ref(false);
    const error = ref(null);
    const products = ref([]);
    const prevStep = () => {
      router.push('/restaurants');
    };
    const nextStep = () => {
      router.push('/confirm');
    };
    const fetchProduct = async () => {
      loading.value = true;
      error.value = null;
      try {
        await productStore.fetchProductByCode(code.value);
        const productDetail = productStore.product;
        products.value = productDetail.products;

        // 初始化 selectedItems
        products.value.forEach(product => {
          product.productSelectDetail.rounds.forEach(round => {
            let selectedList = [];
            if (round.saleQty === round.items.length) {
              // 全选
              selectedList = round.items.filter(item => !item.disabled).map(item => item.id || item.itemSkuCode);
            } else {
              // 默认选中第一个未禁用的
              const defaultItem = round.items.find(item => !item.disabled);
              if (defaultItem) {
                selectedList = [defaultItem.id || defaultItem.itemSkuCode];
              }
            }
            const selectedId = `${product._local_id}_${round.id}`;
            if (!selectedItems.value[selectedId]) {
              selectedItems.value[selectedId] = selectedList;
            }
          });
        });
        window.selectedItems = selectedItems;
      } catch (err) {
        error.value = err;
      } finally {
        loading.value = false;
      }
    };
    const openCustomization = () => {
      customizationVisible.value = true;
    };
    const closeCustomization = () => {
      customizationVisible.value = false;
    };
    const confirmCustomization = () => {};
    const confirmSelection = () => {
      const allSelected = products.value.every(product => {
        return product.productSelectDetail.rounds.every(round => {
          const selectedId = `${product._local_id}_${round.id}`;
          return selectedItems.value[selectedId]?.length === (round.saleQty || 1);
        });
      });
      if (allSelected) {
        // 处理确认选择的逻辑
        const selectedProducts = products.value.map(product => {
          const selectedRounds = product.productSelectDetail.rounds.map(round => {
            const selectedId = `${product._local_id}_${round.id}`;
            const selectedItemsInRound = round.items.filter(item => selectedItems.value[selectedId].includes(item.id || item.itemSkuCode));
            return {
              ...round,
              items: selectedItemsInRound
            };
          });
          return {
            ...product,
            productSelectDetail: {
              ...product.productSelectDetail,
              rounds: selectedRounds
            }
          };
        });
        orderStore.setProducts(selectedProducts);
        nextStep();
      } else {
        ElMessage({
          message: '请选择相关商品',
          type: 'warning'
        });
      }
    };
    onMounted(() => {
      fetchProduct();
    });
    const {
      product
    } = storeToRefs(productStore);
    return (_ctx, _cache) => {
      const _component_el_button = _resolveComponent("el-button");
      const _directive_loading = _resolveDirective("loading");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createVNode(_unref(TopNav), {
        title: "兑换码商品列表"
      }), _createVNode(_unref(StepIndicator), {
        activeStep: 1
      })]), _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_3, [loading.value ? (_openBlock(), _createElementBlock("div", _hoisted_4, "加载中...")) : _createCommentVNode("", true), error.value ? (_openBlock(), _createElementBlock("div", _hoisted_5, "错误: " + _toDisplayString(error.value.message), 1)) : _createCommentVNode("", true), _unref(product) ? (_openBlock(true), _createElementBlock(_Fragment, {
        key: 2
      }, _renderList(products.value, product => {
        return _openBlock(), _createElementBlock("div", null, [_createVNode(_unref(ProductItem), {
          product: product,
          onOpenCustomization: openCustomization
        }, null, 8, ["product"])]);
      }), 256)) : _createCommentVNode("", true)])), [[_directive_loading, loading.value]]), _createElementVNode("div", _hoisted_6, [_createVNode(_component_el_button, {
        type: "success",
        color: "#fe813b",
        class: "select-button",
        round: "",
        onClick: confirmSelection,
        style: {
          "color": "#fff"
        }
      }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [_createTextVNode("确认选择")])),
        _: 1
      })]), customizationVisible.value ? (_openBlock(), _createBlock(_unref(Customization), {
        key: 0,
        onConfirm: confirmCustomization,
        onClose: closeCustomization
      })) : _createCommentVNode("", true)]);
    };
  }
};