import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_1 = {
  class: "order-product-container"
};
const _hoisted_2 = {
  class: "order-item-info"
};
const _hoisted_3 = {
  key: 0,
  class: "order-item-title"
};
export default {
  __name: 'order-product',
  props: {
    orderDetails: {
      type: Object,
      required: true
    }
  },
  setup(__props) {
    const props = __props;
    return (_ctx, _cache) => {
      const _component_el_divider = _resolveComponent("el-divider");
      const _component_el_image = _resolveComponent("el-image");
      return _openBlock(), _createElementBlock("div", _hoisted_1, [_cache[0] || (_cache[0] = _createElementVNode("h3", null, "餐品详情", -1)), _createVNode(_component_el_divider), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(__props.orderDetails.products, product => {
        return _openBlock(), _createElementBlock("div", {
          class: "order-product",
          key: product.id
        }, [_createVNode(_component_el_image, {
          class: "order-item-image",
          src: product.imageUrl,
          fit: "cover"
        }, null, 8, ["src"]), _createElementVNode("div", _hoisted_2, [false ? (_openBlock(), _createElementBlock("h3", _hoisted_3, _toDisplayString(product.name), 1)) : _createCommentVNode("", true), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(product.items, item => {
          return _openBlock(), _createElementBlock("p", {
            class: "order-item-description",
            key: item.id
          }, _toDisplayString(item.itemQty) + " × " + _toDisplayString(item.itemSkuName), 1);
        }), 128))])]);
      }), 128))]);
    };
  }
};